var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--view",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" " + _vm._s(_vm.board.subject) + " ")])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('ul', {
    staticClass: "board-view-data"
  }, [_c('li', [_vm._v("작성자 : " + _vm._s((_vm.board.writer.name || "").substr(0, 2)) + _vm._s((_vm.board.writer.name || "").substr(2).replace(/./g, '*')))]), _c('li', [_vm._v("작성일 : " + _vm._s(_vm.board.createDate.toDateTime()))]), _c('li', [_vm._v("조회수 : " + _vm._s(_vm.board.views))])])])]), false ? _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-view-file"
  }, [_c('span', {
    staticClass: "head"
  }, [_c('svg', {
    staticStyle: {
      "enable-background": "new 0 0 409 513.6"
    },
    attrs: {
      "version": "1.1",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "x": "0px",
      "y": "0px",
      "viewBox": "0 0 409 513.6",
      "xml:space": "preserve"
    }
  }, [_c('path', {
    attrs: {
      "d": "M41.5,452.8v-353c0-11,9-20,20-20h60v-19c0-11,9-20,20-20c11,0,20,9,20,20v141c0,11-9,20-20,20c-11,0-20-9-20-20v-82h-40v82c0,33.1,26.9,60,60,60c33.1,0,60-26.9,60-60v-122h145.9c11,0,20,9,20,20v353c0,11-9,20-20,20H61.5C50.5,472.8,41.5,463.8,41.5,452.8M85.3,39.8H61.5c-33.1,0-60,26.9-60,60v353c0,33.1,26.9,60,60,60h285.9c33.1,0,60-26.9,60-60v-353c0-33.1-26.9-60-60-60H197.7c-8.5-22.8-30.5-39-56.2-39S93.9,17,85.3,39.8"
    }
  })]), _vm._v(" 첨부파일 : ")]), _vm._m(0)])])]) : _vm._e(), _vm.board.content ? _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.content)
    }
  })])])]) : _vm._e(), _vm.board.reply ? _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.reply)
    }
  })])])]) : _vm._e(), (_vm.board.relativeProducts || []).filter(function (product) {
    return product._id;
  }).length ? _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('h1', {
    staticClass: "title"
  }, [_vm._v("관련제품")]), _c('ul', {
    staticClass: "board-product-lists"
  }, _vm._l(_vm.board.relativeProducts.filter(function (product) {
    return product._id;
  }), function (product) {
    return _c('li', {
      staticClass: "board-product-list"
    }, [_c('a', {
      staticClass: "board-product",
      attrs: {
        "href": `/collection/${product.paperCollection.code}?code=${product.code}`
      }
    }, [_c('div', {
      staticClass: "board-product__thumb"
    }, [_c('span', {
      staticClass: "board-product__link"
    }, [_c('img', {
      staticClass: "board-product__thumbnail",
      attrs: {
        "src": product.thumb,
        "alt": ""
      }
    })])]), _c('div', {
      staticClass: "board-product__info"
    }, [_c('span', {
      staticClass: "board-product__text"
    }, [_vm._v(_vm._s(product.paperCollection.name))]), _c('span', {
      staticClass: "board-product__title"
    }, [_vm._v(_vm._s(product.name) + " " + _vm._s(product.nameEn))]), _c('span', {
      staticClass: "board-product__text"
    }, [_vm._v("[" + _vm._s(product.code) + "]")])])])]);
  }), 0)])]) : _vm._e()])]), false ? _c('div', {
    staticClass: "board-table board-table--preview",
    attrs: {
      "role": "table"
    }
  }, [_vm._m(1)]) : _vm._e(), _c('div', {
    staticClass: "board-buttons"
  }, [_vm._m(2), _c('div', {
    staticClass: "right"
  }, [_vm.payload && _vm.payload._user == _vm.board._user ? _c('a', {
    staticClass: "button button--border",
    on: {
      "click": function ($event) {
        return _vm.$emit('edit');
      }
    }
  }, [_c('span', [_vm._v("수정")])]) : _vm._e(), _vm.payload && _vm.payload._user == _vm.board._user ? _c('a', {
    staticClass: "button button--primary",
    on: {
      "click": function ($event) {
        return _vm.$emit('remove');
      }
    }
  }, [_c('span', [_vm._v("삭제")])]) : _vm._e()])])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "lists"
  }, [_c('li', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("파일제목_01.jpg,")])]), _c('li', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("파일제목_02.jpg,")])]), _c('li', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("파일제목_03.jpg,")])]), _c('li', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("파일제목_04.jpg,")])]), _c('li', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("파일제목_05.jpg")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" 이전글 ")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("이전 게시글 제목입니다.")])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(" 다음글 ")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "title"
  }, [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("다음 게시글 제목입니다.")])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "left"
  }, [_c('a', {
    staticClass: "button button--border",
    attrs: {
      "href": ""
    }
  }, [_c('span', [_vm._v("리스트")])])]);

}]

export { render, staticRenderFns }