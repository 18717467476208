<template>
    <div class="board-container">
        <div class="board-photo">
            <ul>
                <li v-for="board in boards">
                    <div class="photo-wrap" @click="navigate(board)">
                        <div class="photo-header">
                            <div class="photo-img">
                                <a role="link" :style="{ backgroundImage: `url('${board.thumb}')` }"></a>
                            </div>
                        </div>
                        <div class="photo-body">
                            <a role="link">
                                <h2 class="photo-title">{{ board.subject }}</h2>
                                <p class="photo-text">{{ board.summary }}</p>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <pagination-component v-model="page" :count="count" @input="$emit('input', page)"></pagination-component>
    </div>
</template>

<script>
import PaginationComponent from '@/components/client/pagination-component.vue';
export default{
    components: {
        PaginationComponent
    },
    props: {
        value: { type: Number, default: 1 },
        boards: { type: Array, default: [] },
        count: { type: Number, default: 0 },
    },
    data(){
        return {
            page: this.value
        }
    },
    methods: {
        navigate(board){
            this.$router.push({
                path: `${this.$route.path}/${board._id}`
            });
        }
    }
}
</script>
