var render = function render(){
  var _vm$collection;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub wrap--sample"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_vm._m(0), _c('div', {
    staticClass: "center-contents"
  }, [_c('div', {
    staticClass: "container2"
  }, [_c('div', {
    staticClass: "sample-row"
  }, [_c('div', {
    staticClass: "sample-col"
  }, [_c('div', {
    staticClass: "sample-step"
  }, [_c('ul', [_c('li', {
    class: _vm.step1,
    on: {
      "click": _vm.init
    }
  }, [_vm._v("컬렉션 선택하기")]), _c('li', {
    class: _vm.btnStep2
  }, [_c('i', {
    staticClass: "icon icon-arrow-circle"
  })]), _c('li', {
    class: _vm.step2,
    on: {
      "click": function ($event) {
        return _vm.getCollection('step');
      }
    }
  }, [_vm._v("패턴 선택하기")]), _c('li', {
    class: _vm.btnStep3
  }, [_c('i', {
    staticClass: "icon icon-arrow-circle"
  })]), _c('li', {
    class: _vm.step3
  }, [_vm._v("제품 선택하기")])])]), _vm.showCollections ? _c('div', _vm._l(_vm.collections, function (collection, index) {
    return _c('div', {
      key: index,
      staticClass: "book-wrap"
    }, [_c('div', {
      staticClass: "book",
      on: {
        "click": function ($event) {
          return _vm.getCollection(collection);
        }
      }
    }, [_c('div', {
      staticClass: "front"
    }, [_c('div', {
      staticClass: "cover"
    }, [_c('img', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: collection.type == '내수용',
        expression: "collection.type == '내수용'"
      }],
      attrs: {
        "src": collection.image,
        "alt": "내수용"
      }
    })])]), _c('div', {
      staticClass: "left-side"
    }, [_c('h2', [_c('span', [_vm._v(_vm._s(collection.name))]), _c('span', [_vm._v(_vm._s(collection.sideText))])])])]), _c('div', {
      staticClass: "bottom-content"
    }, [_c('h1', [_vm._v(_vm._s(collection.name))]), _c('div', {
      staticClass: "hash-div"
    }, _vm._l(collection.hashtags, function (hashtag, index) {
      return _c('div', {
        key: index,
        attrs: {
          "type": "button",
          "name": "button"
        }
      }, [_vm._v("#" + _vm._s(hashtag.trim()))]);
    }), 0)])]);
  }), 0) : _vm._e(), _vm.showPatterns ? _c('div', {
    staticClass: "index-container"
  }, [_c('div', {
    staticClass: "index-wrapper"
  }, _vm._l((_vm$collection = _vm.collection) === null || _vm$collection === void 0 ? void 0 : _vm$collection.children, function (texture, index) {
    return _c('div', {
      key: index,
      staticClass: "index-items"
    }, [_c('div', {
      staticClass: "index-slide",
      attrs: {
        "role": "button"
      },
      on: {
        "click": function ($event) {
          return _vm.getPatterns(texture);
        }
      }
    }, [_c('span', {
      staticClass: "thumbnail",
      style: {
        backgroundImage: `url('${texture.thumb}')`
      }
    }), _c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(texture.name))])])]);
  }), 0)]) : _vm._e(), _vm.showProducts ? _c('div', {
    staticClass: "index-container index-container--put"
  }, [_c('div', {
    staticClass: "index-wrapper"
  }, _vm._l(_vm.papers, function (paper, index) {
    return _c('div', {
      key: index,
      staticClass: "index-items"
    }, [_c('div', {
      staticClass: "index-slide"
    }, [_c('span', {
      staticClass: "thumbnail",
      style: {
        'backgroundImage': `url('${paper === null || paper === void 0 ? void 0 : paper.images[0]}')`
      }
    }), _c('span', {
      staticClass: "num"
    }, [_vm._v(_vm._s(paper.code))])]), _c('div', {
      staticClass: "btn-wrap btn-wrap--sm align-center"
    }, [_c('button', {
      staticClass: "button button--sm button--round button--border-point",
      on: {
        "click": function ($event) {
          return _vm.addToCart(paper);
        }
      }
    }, [_vm._v("담기")])])]);
  }), 0)]) : _vm._e()]), _c('div', {
    staticClass: "sample-col sample-col--toast"
  }, [_vm._m(1), _c('button', {
    staticClass: "sample-toast-toggle-btn",
    on: {
      "click": function ($event) {
        return _vm.sampleToggle();
      }
    }
  }, [_c('i', {
    staticClass: "icon icon-up-rounded"
  })]), _c('div', {
    staticClass: "sample-wrap"
  }, [_vm.basket.length && !!_vm.accessToken ? _c('div', {
    staticClass: "sample-check-wrap"
  }, [_c('div', {
    staticClass: "sample-check-row"
  }, _vm._l(_vm.cartsMap, function (map, index) {
    return _c('div', {
      key: index,
      staticClass: "sample-check-col"
    }, [_c('div', {
      staticClass: "sample-check"
    }, [_c('label', {
      staticClass: "check check--primary"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selected,
        expression: "selected"
      }],
      staticClass: "input",
      attrs: {
        "type": "checkbox",
        "name": "selected"
      },
      domProps: {
        "value": map,
        "checked": Array.isArray(_vm.selected) ? _vm._i(_vm.selected, map) > -1 : _vm.selected
      },
      on: {
        "change": function ($event) {
          var $$a = _vm.selected,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = map,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.selected = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selected = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selected = $$c;
          }
        }
      }
    }), _c('i', {
      staticClass: "icon"
    })]), _c('div', {
      staticClass: "sample-check__img",
      style: {
        'backgroundImage': `url('${map.product.images[0]}')`
      }
    }), _c('div', {
      staticClass: "sample-check__content"
    }, [_c('div', {
      staticClass: "sample-check__tit"
    }, [_vm._v(_vm._s(map.product.code))]), _c('div', {
      staticClass: "sample-check__txt"
    }, [_vm._v(_vm._s(map.product.name))])]), _c('button', {
      staticClass: "sample-check__close",
      on: {
        "click": function ($event) {
          return _vm.remove(index);
        }
      }
    }, [_c('i', {
      staticClass: "icon icon-close-grey"
    })])])]);
  }), 0), _c('div', {
    staticClass: "btn-wrap align-center d-none d-md-block"
  }, [_c('button', {
    staticClass: "button button--round button--point button--lg",
    on: {
      "click": _vm.order
    }
  }, [_vm._v("샘플신청하기")])])]) : _vm._e(), !_vm.basket.length && !!_vm.accessToken ? _c('div', {
    staticClass: "sample-notselected"
  }, [_vm._m(2)]) : _vm._e(), !_vm.accessToken ? _c('div', {
    staticClass: "sample-nonmember"
  }, [_c('div', [_vm._m(3), _c('div', {
    staticClass: "btn-wrap align-center d-none d-md-block"
  }, [_c('button', {
    staticClass: "button button--round button--point button--lg",
    on: {
      "click": function ($event) {
        return _vm.$router.push('/login?code=sample');
      }
    }
  }, [_vm._v("로그인하기")])])])]) : _vm._e()]), _c('div', {
    staticClass: "sample-toast-btn-wrap"
  }, [_c('button', {
    staticClass: "button button--square button--point",
    on: {
      "click": _vm.order
    }
  }, [_vm._v("샘플신청하기")])])])])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "collection-index-container"
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("COMMUNITY")]), _c('ul', {
    staticClass: "breadcrumb"
  }, [_c('li', {
    staticClass: "breadcrumb__list"
  }, [_vm._v("항상 고객을 생각하는 마음으로 최선을 다하는 디아이디")])])])]), _c('div', {
    staticClass: "center-ban"
  }, [_c('h1', {
    staticClass: "center-ban__title"
  }, [_vm._v("디아이디 커뮤니티")]), _c('p', {
    staticClass: "center-ban__pr"
  }, [_vm._v("고객님과 함께하는 디아이디 커뮤니티 입니다.")])]), _c('div', {
    staticClass: "center-tab"
  }, [_c('ul', {
    staticClass: "tab-list tab-list--block"
  }, [_c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/center/notice",
      "name": "button"
    }
  }, [_vm._v("공지사항")])]), _c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/community/sponsor",
      "name": "button"
    }
  }, [_vm._v("협찬정보")])]), _c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/community/event",
      "name": "button"
    }
  }, [_vm._v("이벤트")])]), _c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/community/interior",
      "name": "button"
    }
  }, [_vm._v("인테리어 상담")])]), _c('li', {
    staticClass: "on"
  }, [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/community/sample",
      "name": "button"
    }
  }, [_vm._v("샘플신청하기")])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title-wrap"
  }, [_c('h3', {
    staticClass: "page-title18"
  }, [_vm._v("샘플신청")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('i', {
    staticClass: "icon icon-plus"
  }), _c('p', {
    staticClass: "page-text18 color-grey9 align-center"
  }, [_vm._v("아직 샘플을 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("선택하지 않았습니다.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "page-text18 color-grey5 align-center"
  }, [_vm._v("로그인 후 "), _c('br', {
    staticClass: "pc"
  }), _vm._v("샘플신청하기가 가능합니다.")]);

}]

export { render, staticRenderFns }