<template>
    <div class="board-container">
        <ul class="tab-list tab-list--tooth">
            <li :class="{ 'on': category == '진행중 이벤트'}"><a class="button" role="link" @click="$emit('changeCategory', '진행중 이벤트')">진행중 이벤트</a></li>
            <li :class="{ 'on': category == '종료된 이벤트'}"><a class="button" role="link" @click="$emit('changeCategory', '종료된 이벤트')">종료된 이벤트</a></li>
        </ul>
        <ul class="tab-contents">
            <li class="on">
                <div class="board-photo board-photo--basic2">
                    <ul>
                        <li v-for="board in boards" @click="navigate(board)">
                            <div class="photo-wrap">
                                <div class="photo-header">
                                    <div class="photo-img">
                                        <a role="link" :style="{ backgroundImage: `url('${board.thumb}')` }"></a>
                                    </div>
                                </div>
                                <div class="photo-body">
                                    <a role="link">
                                        <h2 class="photo-title">{{ board.subject }}</h2>
                                        <p class="photo-text">{{ board.summary }}</p>
                                        <p class="photo-category" v-if="board.period"><strong class="label">기간</strong><span>{{ board.period }}</span></p>
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <pagination-component v-model="page" :count="count" @input="$emit('input', page)"></pagination-component>
            </li>
            <li>

            </li>
        </ul>
    </div>
</template>

<script>
import PaginationComponent from '@/components/client/pagination-component.vue';
export default{
    components: {
        PaginationComponent
    },
    props: {
        category: { type: String, default: null },
        value: { type: Number, default: 1 },
        boards: { type: Array, default: [] },
        count: { type: Number, default: 0 },
    },
    data() {
        return {
            page: 1
        }
    },
    methods: {
        navigate(board){
            this.$router.push({
                path: `${this.$route.path}/${board._id}`
            });
        }
    }
}
</script>
