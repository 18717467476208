<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="container">
                <div class="collection-index-container">
                    <div class="page-head">
                        <div class="page-head__inner">
                            <h1 class="page-head__title">COMMUNITY</h1>
                            <ul class="breadcrumb">
                                <li class="breadcrumb__list">항상 고객을 생각하는 마음으로 최선을 다하는 디아이디</li>
                                <!-- <li class="breadcrumb__list">컬렉션</li>
                                <li class="breadcrumb__list">국내</li>
                                <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li> -->
                            </ul>
                        </div>
                    </div>

                    <div class="community-wrap">
                        <img src="/images/sub/community/community_top_banner2.png" alt="" class="community-banner">
                        <div class="community-button-wrap">
                            <button type="button" class="off community-button"  onclick="window.location='/community/faq'" name="button">FaQ</button>
                            <button type="button" class="on community-button" onclick="window.location='/community/notice/list'" name="button">공지사항</button>
                            <button type="button" class="off community-button" onclick="window.location='/community/sponsor/list'" name="button">협찬정보</button>
                            <button type="button" class="off community-button" onclick="window.location='/community/event/list'" name="button">이벤트</button>
                            <button type="button" class="off community-button" onclick="window.location='/community/interior'" name="button">인테리어 상담</button>
                        </div>
                    </div>
                    <div class="center-board">
                        <!-- 1. 게시판 리스트: 베이직 스킨 -->
                        <board-basic-list></board-basic-list>
                        <!-- 2. 게시판 작성: 베이직 스킨 -->
                        <board-basic-input></board-basic-input>
                        <!-- 3. 게시판 뷰: 베이직 스킨 -->
                        <board-basic-view></board-basic-view>
                    </div>
                </div>
            </div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from '@/components/client/inc/main-header.vue';
import MainFooter from '@/components/client/inc/main-footer.vue';
import MypageNavigation from "../../../components/client/mypage-navigation.vue";
import BoardBasicList from "@/components/client/board/skin/board-basic/list.vue";
import BoardBasicInput from "@/components/client/board/skin/board-basic/input.vue";
import BoardBasicView from "@/components/client/board/skin/board-basic/view.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
        MypageNavigation,
        BoardBasicList,
        BoardBasicView,
        BoardBasicInput
	},
	created() {
	},
	mounted() {
		this.init();
	},
		}
</script>
