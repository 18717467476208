var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "collection-index-container"
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _c('div', {
    staticClass: "center-board"
  }, [_c('board-photo-basic2-list', {
    attrs: {
      "category": _vm.category,
      "boards": _vm.boards,
      "count": _vm.pageCount
    },
    on: {
      "changeCategory": _vm.changeCategory
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("COMMUNITY")]), _c('ul', {
    staticClass: "breadcrumb"
  }, [_c('li', {
    staticClass: "breadcrumb__list"
  }, [_vm._v("항상 고객을 생각하는 마음으로 최선을 다하는 디아이디")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "center-ban"
  }, [_c('h1', {
    staticClass: "center-ban__title"
  }, [_vm._v("디아이디 커뮤니티")]), _c('p', {
    staticClass: "center-ban__pr"
  }, [_vm._v("고객님과 함께하는 디아이디 커뮤니티 입니다.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "center-tab"
  }, [_c('ul', {
    staticClass: "tab-list tab-list--block"
  }, [_c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/center/notice",
      "name": "button"
    }
  }, [_vm._v("공지사항")])]), _c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/community/sponsor",
      "name": "button"
    }
  }, [_vm._v("협찬정보")])]), _c('li', {
    staticClass: "on"
  }, [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/community/event",
      "name": "button"
    }
  }, [_vm._v("이벤트")])]), _c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/community/interior",
      "name": "button"
    }
  }, [_vm._v("인테리어 상담")])]), _c('li', [_c('a', {
    staticClass: "button",
    attrs: {
      "href": "/community/sample",
      "name": "button"
    }
  }, [_vm._v("샘플신청하기")])])])]);

}]

export { render, staticRenderFns }