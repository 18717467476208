<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="container">
                <div class="collection-index-container">
                    <div class="page-head">
                        <div class="page-head__inner">
                            <h1 class="page-head__title">COMMUNITY</h1>
                            <ul class="breadcrumb">
                                <li class="breadcrumb__list">항상 고객을 생각하는 마음으로 최선을 다하는 디아이디</li>
                                <!-- <li class="breadcrumb__list">컬렉션</li>
                                <li class="breadcrumb__list">국내</li>
                                <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li> -->
                            </ul>
                        </div>
                    </div>
					<div class="center-ban">
						<h1 class="center-ban__title">디아이디 커뮤니티</h1>
						<p class="center-ban__pr">고객님과 함께하는 디아이디 커뮤니티 입니다.</p>
					</div>
					<div class="center-tab">
						<ul class="tab-list tab-list--block">
							<!-- <li><a class="button" href="/center/faq">FaQ</a></li> -->
							<li><a class="button" href="/center/notice" name="button">공지사항</a></li>
							<li><a class="button" href="/community/sponsor" name="button">협찬정보</a></li>
							<li><a class="button" href="/community/event" name="button">이벤트</a></li>
							<li class="on"><a class="button" href="/community/interior" name="button">인테리어 상담</a></li>
							<li><a class="button" href="/community/sample" name="button">샘플신청하기</a></li>
						</ul>
					</div>
                    <div class="center-board">
                        <!-- 1. 게시판 리스트: 베이직 스킨 -->
						<board-basic-list v-if="!$route.query.write && !$route.query._board" v-model="page" :boards="boards" :count="pageCount" :limit="limit" @write="writeBoard" @click="clickBoard"></board-basic-list>
                        <!-- 2. 게시판 작성: 베이직 스킨 -->
                        <board-basic-input v-if="$route.query.write && board" v-model="board" @save="saveBoard"></board-basic-input>
                        <!-- 3. 게시판 뷰: 베이직 스킨 -->
                        <board-basic-view v-if="!$route.query.write && $route.query._board" :board="board" @remove="removeBoard" @edit="editBoard"></board-basic-view>
                    </div>
                </div>
            </div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from '@/components/client/inc/main-header.vue';
import MainFooter from '@/components/client/inc/main-footer.vue';
import BoardBasicList from "@/components/client/board/skin/board-basic/list.vue";
import BoardBasicInput from "@/components/client/board/skin/board-basic/input.vue";
import BoardBasicView from "@/components/client/board/skin/board-basic/view.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		BoardBasicList,
		BoardBasicInput,
		BoardBasicView,
	},
	data: () => {
		return {
			ready: false,

			board: null,
			boards: [],

			page: 1,
			pageCount: 0,
			limit: 15,
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		async init(){
			try{
				if(!this.$route.query.write && !this.$route.query._board) await this.getBoards();
				if(this.$route.query.write){
					this.board = this.$route.query._board ? await this.getBoard() : {
						subject: null,
						content: null,
						writer: {
							name: this.payload.name,
							email: this.payload.username
						}
					};
				}
				if(!this.$route.query.write && this.$route.query._board) this.board = await this.getBoard();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},
		async search(){
			try{
				await this.getBoards();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		async writeBoard(){
			try{
				this.$router.push({
					query: { write: true }
				});
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		async clickBoard(){
			try{
				await this.postBoard(this.board);
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		async saveBoard(){
			try{
				this.board._id ? await this.putBoard(this.board) : await this.postBoard(this.board);
				alert(this.board._id ? "수정되었습니다" : "등록되었습니다");
				this.$router.go(-1);
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		async removeBoard(board){
			try{
				await this.deleteBoard(this.board);
				alert("삭제되었습니다");
				this.$router.push('?');
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		async editBoard(){
			try{
				this.$router.push({
					query: {
						write: true,
						_board: this.$route.query._board
					}
				})
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},

		getBoards(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/boards', {
						headers: {
							"skip": (this.page - 1) * this.limit,
							"limit": this.limit
						},
						params: {
							code: "interior-inquire"
						}
					});

					this.boards = res.data.boards;
					this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},
		getBoard(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get(`/api/v1/boards/${this.$route.query._board}`);

					resolve(res.data.board);
				}
				catch(error){
					reject(error);
				}
			});
		},
        postBoard(){
            return new Promise(async (resolve, reject) => {
                try{
                    var board = Object.assign({}, this.board, {
                        code: 'interior-inquire'
                    });

                    var res = await this.$http.post('/api/v1/boards', board);

                    resolve(res.data.board);
                }
                catch(error){
                    reject(error);
                }
            });
        },
        putBoard(){
            return new Promise(async (resolve, reject) => {
                try{
                    var board = Object.assign({}, this.board, {
                        code: this.$route.params.code,
                        relativeProducts: this.board.relativeProducts.map((product) => product._id)
                    });

                    var res = await this.$http.put(`/api/v1/boards/${this.$route.query._board}`, board);

                    resolve(res.data.board);
                }
                catch(error){
                    reject(error);
                }
            });
        },
        deleteBoard(board){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.delete(`/api/v1/boards/${board._id}`);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },
	},
	computed: {
		payload(){
			return this.$store.state.payload;
		},
		route(){
			return this.$route;
		}
	},
	watch :{
		page(){
			this.search();
		},
		route(){
			window.location.reload();
		}
	}
}
</script>
