var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "collection-index-container"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "center-board"
  }, [_c('board-faq-list', {
    attrs: {
      "faqs": _vm.faqs
    }
  })], 1)])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("COMMUNITY")]), _c('ul', {
    staticClass: "breadcrumb"
  }, [_c('li', {
    staticClass: "breadcrumb__list"
  }, [_vm._v("항상 고객을 생각하는 마음으로 최선을 다하는 디아이디")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "community-wrap"
  }, [_c('img', {
    staticClass: "community-banner",
    attrs: {
      "src": "/images/sub/community/community_top_banner2.png",
      "alt": ""
    }
  }), _c('div', {
    staticClass: "community-button-wrap"
  }, [_c('button', {
    staticClass: "on community-button",
    attrs: {
      "type": "button",
      "onclick": "window.location='/center/faq'",
      "name": "button"
    }
  }, [_vm._v("FaQ")]), _c('button', {
    staticClass: "off community-button",
    attrs: {
      "type": "button",
      "onclick": "window.location='/center/notice'",
      "name": "button"
    }
  }, [_vm._v("공지사항")]), _c('button', {
    staticClass: "off community-button",
    attrs: {
      "type": "button",
      "onclick": "window.location='/community/sponsor/list'",
      "name": "button"
    }
  }, [_vm._v("협찬정보")]), _c('button', {
    staticClass: "off community-button",
    attrs: {
      "type": "button",
      "onclick": "window.location='/community/event/list'",
      "name": "button"
    }
  }, [_vm._v("이벤트")]), _c('button', {
    staticClass: "off community-button",
    attrs: {
      "type": "button",
      "onclick": "window.location='/community/interior'",
      "name": "button"
    }
  }, [_vm._v("인테리어 상담")])])]);

}]

export { render, staticRenderFns }