var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('ul', {
    staticClass: "tab-list tab-list--tooth"
  }, [_c('li', {
    class: {
      'on': _vm.category == '진행중 이벤트'
    }
  }, [_c('a', {
    staticClass: "button",
    attrs: {
      "role": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeCategory', '진행중 이벤트');
      }
    }
  }, [_vm._v("진행중 이벤트")])]), _c('li', {
    class: {
      'on': _vm.category == '종료된 이벤트'
    }
  }, [_c('a', {
    staticClass: "button",
    attrs: {
      "role": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeCategory', '종료된 이벤트');
      }
    }
  }, [_vm._v("종료된 이벤트")])])]), _c('ul', {
    staticClass: "tab-contents"
  }, [_c('li', {
    staticClass: "on"
  }, [_c('div', {
    staticClass: "board-photo board-photo--basic2"
  }, [_c('ul', _vm._l(_vm.boards, function (board) {
    return _c('li', {
      on: {
        "click": function ($event) {
          return _vm.navigate(board);
        }
      }
    }, [_c('div', {
      staticClass: "photo-wrap"
    }, [_c('div', {
      staticClass: "photo-header"
    }, [_c('div', {
      staticClass: "photo-img"
    }, [_c('a', {
      style: {
        backgroundImage: `url('${board.thumb}')`
      },
      attrs: {
        "role": "link"
      }
    })])]), _c('div', {
      staticClass: "photo-body"
    }, [_c('a', {
      attrs: {
        "role": "link"
      }
    }, [_c('h2', {
      staticClass: "photo-title"
    }, [_vm._v(_vm._s(board.subject))]), _c('p', {
      staticClass: "photo-text"
    }, [_vm._v(_vm._s(board.summary))]), board.period ? _c('p', {
      staticClass: "photo-category"
    }, [_c('strong', {
      staticClass: "label"
    }, [_vm._v("기간")]), _c('span', [_vm._v(_vm._s(board.period))])]) : _vm._e()])])])]);
  }), 0)]), _c('pagination-component', {
    attrs: {
      "count": _vm.count
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.page);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('li')])]);

}
var staticRenderFns = []

export { render, staticRenderFns }