var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('div', {
    staticClass: "board-product-contaienr"
  }, [_c('img', {
    attrs: {
      "src": _vm.board.thumb
    }
  }), _c('div', [_vm._v("제목: " + _vm._s(_vm.board.subject))]), _c('div', [_vm._v("한줄요약: " + _vm._s(_vm.board.summary))]), _c('div', [_vm._v("내용: "), _c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.content.replace(/\n/g, '<br>'))
    }
  })]), (_vm.board.relativeProducts || []).filter(function (product) {
    return product._id;
  }).length ? _c('h1', {
    staticClass: "title"
  }, [_vm._v("협찬제품")]) : _vm._e(), (_vm.board.relativeProducts || []).filter(function (product) {
    return product._id;
  }).length ? _c('ul', {
    staticClass: "board-product-lists"
  }, _vm._l(_vm.board.relativeProducts.filter(function (product) {
    return product._id;
  }), function (product) {
    return _c('li', {
      staticClass: "board-product-list"
    }, [_c('a', {
      staticClass: "board-product",
      attrs: {
        "href": `/collection/${product.paperCollection.code}?code=${product.code}`
      }
    }, [_c('div', {
      staticClass: "board-product__thumb"
    }, [_c('span', {
      staticClass: "board-product__link"
    }, [_c('img', {
      staticClass: "board-product__thumbnail",
      attrs: {
        "src": product.thumb,
        "alt": ""
      }
    })])]), _c('div', {
      staticClass: "board-product__info"
    }, [_c('span', {
      staticClass: "board-product__text"
    }, [_vm._v(_vm._s(product.paperCollection.name))]), _c('span', {
      staticClass: "board-product__title"
    }, [_vm._v(_vm._s(product.name) + " " + _vm._s(product.nameEn))]), _c('span', {
      staticClass: "board-product__text"
    }, [_vm._v("[" + _vm._s(product.code) + "]")])])])]);
  }), 0) : _vm._e()]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }