<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
				<div class="collection-index-container">
					<div class="page-head">
						<div class="page-head__inner">
							<h1 class="page-head__title">COMMUNITY</h1>
							<ul class="breadcrumb">
								<li class="breadcrumb__list">항상 고객을 생각하는 마음으로 최선을 다하는 디아이디</li>
								<!-- <li class="breadcrumb__list">컬렉션</li>
								<li class="breadcrumb__list">국내</li>
								<li class="breadcrumb__list">디앤디 컬렉션 인덱스</li> -->
							</ul>
						</div>
					</div>

					<div class="community-wrap">
						<img src="/images/sub/community/community_top_banner2.png" alt="" class="community-banner">
						<div class="community-button-wrap">
							<button type="button" class="on community-button"  onclick="window.location='/center/faq'" name="button">FaQ</button>
							<button type="button" class="off community-button" onclick="window.location='/center/notice'" name="button">공지사항</button>
							<button type="button" class="off community-button" onclick="window.location='/community/sponsor/list'" name="button">협찬정보</button>
							<button type="button" class="off community-button" onclick="window.location='/community/event/list'" name="button">이벤트</button>
							<button type="button" class="off community-button" onclick="window.location='/community/interior'" name="button">인테리어 상담</button>
						</div>
					</div>
					<div class="center-board">
						<!-- 게시판 리스트: FAQ 스킨 -->
                        <board-faq-list :faqs="faqs"></board-faq-list>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";
import BoardFaqList from "../../../components/client/board/skin/board-faq/list.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		BoardFaqList
	},
	data: () => {
		return {
			faqs: []
		};
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		async init() {
			try{
				await this.getFaqs();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message.toString());
			}
		},

		getFaqs(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/center/faqs');

					this.faqs = res.data.faqs;
					this.$nextTick(() => {

					});

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		}
	},
}
</script>
