<template>
    <div>
        <notification-list v-if="$route.query._notification === undefined"></notification-list>
        <notification-view v-else></notification-view>
    </div>
</template>

<script>
import NotificationList from './notifications/notification-list.vue';
import NotificationView from './notifications/notification-view.vue';
export default{
    components: {
        NotificationList,
        NotificationView
    }
}
</script>
