<template>
    <div class="board-container">
        <div role="table" class="board-table board-table--notice">
            <div role="rowgroup">
                <div role="row">
                    <div role="columnheader" class="num">번호</div>
                    <div role="columnheader" class="title">제목</div>
                    <div role="columnheader" class="date">작성일</div>
                </div>
            </div>
            <div role="rowgroup">
                <div v-for="(notification, index) in notifications" role="row">
                    <div role="cell" class="num">{{ (page-1)*limit + index + 1 }}</div>
                    <div role="cell"><div class="title"><a @click="$router.push({ query: { _notification: notification._id }})">{{ notification.subject }}</a></div></div>
                    <div role="cell" class="date"><span class="date">{{ notification.createDate.toDate() }}</span></div>
                </div>
            </div>
        </div>
        <pagination-component v-model="page" :count="pageCount" @input="search"></pagination-component>
    </div>
</template>

<script>
import PaginationComponent from "@/components/client/pagination-component.vue";

export default{
    components: {
        PaginationComponent
    },
    data() {
        return {
            page: 1,
            pageCount: 0,
            limit: 20,

            notifications: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init() {
			try{
				this.search(false);
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
			}
		},
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: Object.assign({}, { page: this.page })
                });
            }

            var { summary, notifications } = await this.getNotifications({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                }
            });

            this.notifications = notifications;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
		getNotifications(data){
			return this.$http.get('/api/v1/center/notifications', data).then(res => res.data);
		}
    }
}
</script>
