<template>
    <div class="board-container">
        <ul class="tab-list tab-list--tooth">
            <li :class="{ 'on': type == '회원서비스' }"><a class="button" @click="type='회원서비스'">회원서비스</a></li>
            <li :class="{ 'on': type == '상품' }"><a class="button" @click="type='상품'">상품</a></li>
            <li :class="{ 'on': type == '배송' }"><a class="button" @click="type='배송'">배송</a></li>
            <li :class="{ 'on': type == '교환/반품' }"><a class="button" @click="type='교환/반품'">교환/반품</a></li>
            <li :class="{ 'on': type == '입금/결제' }"><a class="button" @click="type='입금/결제'">입금/결제</a></li>
            <li :class="{ 'on': type == '기타' }"><a class="button" @click="type='기타'">기타</a></li>
        </ul>
        <div role="table" class="board-table board-table--faq">
            <div v-for="faq in faqs.filter((faq) => faq.type == type)" role="rowgroup">
                <div role="row" class="question" data-idx="1" :data-answer="faq.opened ? 'opened' : 'closed'" @click="faqs.forEach((item) => $set(item, 'opened', item == faq ? !faq.opened : false));">
                    <div role="cell">
                        <div class="title"><a role="button"><i class="icon">Q</i>{{ faq.subject }}</a></div>
                    </div>
                </div>
                <div role="row" class="answer" data-idx-answer="1">
                    <div role="cell">
                        <div class="answer">
                            <div class="title"><i class="icon">A</i>자주 묻는 질문에 대한 답입니다.</div>
                            <p class="pr" v-html="faq.content.replace(/\n/g,'<br>')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props: {
        faqs: { type: Array, default: [] }
    },
    data: () => {
        return {
            type: "회원서비스"
        }
    },
}
</script>
