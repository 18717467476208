<template>
    <div v-if="board" class="board-product-contaienr">

        <img :src="board.thumb">

        <div>제목: {{ board.subject }}</div>
        <div>한줄요약: {{ board.summary }}</div>
        <div>내용: <p v-html="board.content.replace(/\n/g, '<br>')"></p></div>

        <h1 v-if="(board.relativeProducts || []).filter(product => product._id).length" class="title">협찬제품</h1>
        <ul v-if="(board.relativeProducts || []).filter(product => product._id).length" class="board-product-lists">
            <li v-for="product in board.relativeProducts.filter(product => product._id)" class="board-product-list">
                <a class="board-product" :href="`/collection/${product.paperCollection.code}?code=${product.code}`">
                    <div class="board-product__thumb">
                        <span class="board-product__link">
                            <img class="board-product__thumbnail" :src="product.thumb" alt="">
                        </span>
                    </div>
                    <div class="board-product__info">
                        <span class="board-product__text">{{ product.paperCollection.name }}</span>
                        <span class="board-product__title">{{ product.name }} {{ product.nameEn }}</span>
                        <span class="board-product__text">[{{ product.code }}]</span>
                    </div>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default{
	components: {
	},
    props: {
        board: { type: Object, default: null }
    },
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
    computed: {
        payload(){
            return this.$store.state.payload;
        }
    }
}
</script>
