<template>
    <div class="wrap wrap--sub wrap--sample">
        <!-- 헤더 -->
        <main-header></main-header>

        <!-- 메인 -->
        <main class="main" role="main">
            <div class="container">
                <div class="collection-index-container">
                    <div class="page-head">
                        <div class="page-head__inner">
                            <h1 class="page-head__title">COMMUNITY</h1>
                            <ul class="breadcrumb">
                                <li class="breadcrumb__list">항상 고객을 생각하는 마음으로 최선을 다하는 디아이디</li>
                                <!-- <li class="breadcrumb__list">컬렉션</li>
                                <li class="breadcrumb__list">국내</li>
                                <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li> -->
                            </ul>
                        </div>
                    </div>
                    <div class="center-ban">
                        <h1 class="center-ban__title">디아이디 커뮤니티</h1>
                        <p class="center-ban__pr">고객님과 함께하는 디아이디 커뮤니티 입니다.</p>
                    </div>
                    <div class="center-tab">
                        <ul class="tab-list tab-list--block">
                            <li><a class="button" href="/center/notice" name="button">공지사항</a></li>
                            <li><a class="button" href="/community/sponsor" name="button">협찬정보</a></li>
                            <li><a class="button" href="/community/event" name="button">이벤트</a></li>
                            <li><a class="button" href="/community/interior" name="button">인테리어 상담</a></li>
                            <li class="on"><a class="button" href="/community/sample" name="button">샘플신청하기</a></li>
                        </ul>
                    </div>
                </div>

                <div class="center-contents">
                    <div class="container2">
                        <div class="sample-row">
                            <!-- S: 선택하기 -->
                            <div class="sample-col">
                                <div class="sample-step">
                                    <ul>
                                        <!-- 개발확인 : on클래스는 지나온 단계에 current는 현재 단계에 추가 부탁드립니다. -->
                                        <!-- <li class="on">컬렉션 선택하기</li>
                                        <li class="line-height-0 on"><i class="icon icon-arrow-circle"></i></li>
                                        <li class="current on">패턴 선택하기</li>
                                        <li class="line-height-0"><i class="icon icon-arrow-circle"></i></li>
                                        <li class="">제품 선택하기</li> -->
                                        <li @click="init" :class="step1">컬렉션 선택하기</li>
                                        <li :class="btnStep2"><i class="icon icon-arrow-circle"></i></li>
                                        <li @click="getCollection('step')" :class="step2">패턴 선택하기</li>
                                        <li :class="btnStep3"><i class="icon icon-arrow-circle"></i></li>
                                        <li :class="step3">제품 선택하기</li>
                                    </ul>
                                </div>

                                <!-- S: 컬렉션 선택하기 -->
                                <div v-if="showCollections">
                                    <div v-for="(collection, index) in collections" :key="index" class="book-wrap">
                                        <div class="book" @click="getCollection(collection)">
                                            <div class="front">
                                                <div class="cover">
                                                    <img v-show="collection.type == '내수용'" :src="collection.image" alt="내수용">
                                                    <!-- <img v-show="collection.type == '내수용'" class="book-grouping" src="/images/main/domestic_icon.png" alt="내수용"> -->
                                                </div>
                                            </div>
                                            <div class="left-side">
                                                <h2>
                                                    <span>{{ collection.name }}</span>
                                                    <span>{{ collection.sideText }}</span>
                                                </h2>
                                            </div>
                                        </div>
                                        <div class="bottom-content">
                                            <h1>{{ collection.name }}</h1>
                                            <div class="hash-div">
                                                <div v-for="(hashtag, index) in collection.hashtags" :key="index" type="button" name="button">#{{ hashtag.trim() }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- E: 컬렉션 선택하기 -->

                                <!-- S: 패턴 선택하기 -->
                                <div v-if="showPatterns" class="index-container">
                                    <div class="index-wrapper">
                                        <div v-for="(texture, index) in collection?.children" :key="index" class="index-items">
                                            <div @click="getPatterns(texture)" role="button" class="index-slide">
                                                <span class="thumbnail" :style="{ backgroundImage: `url('${texture.thumb}')` }"></span>
                                                <span class="name">{{ texture.name }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- E: 패턴 선택하기 -->

                                <!-- S: 제품 선택하기 -->
                                <div v-if="showProducts" class="index-container index-container--put">
                                    <div class="index-wrapper">
                                        <div v-for="(paper, index) in papers" :key="index" class="index-items">
                                            <div class="index-slide">
                                                <span class="thumbnail" :style="{ 'backgroundImage': `url('${paper?.images[0]}')`}"></span>
                                                <span class="num">{{ paper.code }}</span>
                                            </div>
                                            <div class="btn-wrap btn-wrap--sm align-center">
                                                <button @click="addToCart(paper)" class="button button--sm button--round button--border-point">담기</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- E: 선택하기 -->

                            <!-- S: 샘플신청 -->
                            <!-- 개발확인 : sample-col--toast에 active클래스 추가시 모바일에서 toast영역이 길어집니다. -->
                            <div class="sample-col sample-col--toast">
                                <div class="page-title-wrap">
                                    <h3 class="page-title18">샘플신청</h3>
                                </div>

                                <button class="sample-toast-toggle-btn" @click="sampleToggle()"><i class="icon icon-up-rounded"></i></button>
                                <div class="sample-wrap">
                                    <!-- S: 선택 -->
                                    <div v-if="basket.length && !!accessToken" class="sample-check-wrap">
                                        <div class="sample-check-row">
                                            <!-- 반복 -->
                                            <!-- <div v-for="(map, index) in basket" :key="index" class="sample-check-col">
                                                <div class="sample-check">
                                                    <label class="check check--primary"><input v-model="selected" type="checkbox" name="selected" class="input" :value="map"/><i class="icon"></i></label>
                                                    <div class="sample-check__img" :style="{ 'backgroundImage': `url('${map.product?.images[0]}')`}"></div>
                                                    <div class="sample-check__content">
                                                        <div class="sample-check__tit">{{map.product.code}}</div>
                                                        <div class="sample-check__txt">{{map.product.name}}</div>
                                                    </div>
                                                    <button @click="remove(index)" class="sample-check__close"><i class="icon icon-close-grey"></i></button>
                                                </div>
                                            </div> -->
                                            <div v-for="(map, index) in cartsMap" :key="index" class="sample-check-col">
                                                <div class="sample-check">
                                                    <label class="check check--primary"><input v-model="selected" type="checkbox" name="selected" class="input" :value="map"/><i class="icon"></i></label>
                                                    <div class="sample-check__img" :style="{ 'backgroundImage': `url('${map.product.images[0]}')`}"></div>
                                                    <div class="sample-check__content">
                                                        <div class="sample-check__tit">{{map.product.code}}</div>
                                                        <div class="sample-check__txt">{{map.product.name}}</div>
                                                    </div>
                                                    <button @click="remove(index)" class="sample-check__close"><i class="icon icon-close-grey"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="btn-wrap align-center d-none d-md-block">
                                            <button class="button button--round button--point button--lg" @click="order">샘플신청하기</button>
                                        </div>
                                        <!-- <div class="btn-wrap align-center d-none d-md-block">
                                            <button class="button button--round button--point button--lg" @click="order">샘플신청하기</button>
                                        </div> -->
                                    </div>
                                    <!-- E: 선택 -->

                                    <!-- S: 비선택 -->
                                    <div v-if="!basket.length && !!accessToken" class="sample-notselected">
                                        <div>
                                            <i class="icon icon-plus"></i>
                                            <p class="page-text18 color-grey9 align-center">아직 샘플을 <br class="pc" />선택하지 않았습니다.</p>
                                        </div>
                                    </div>
                                    <!-- E: 비선택 -->
                                    <!-- S: 비선택 -->
                                    <!-- <div v-if="!cartsMap.length" class="sample-notselected">
                                        <div>
                                            <i class="icon icon-plus"></i>
                                            <p class="page-text18 color-grey9 align-center">아직 샘플을 <br class="pc" />선택하지 않았습니다.</p>
                                        </div>
                                    </div> -->
                                    <!-- E: 비선택 -->

                                    <!-- S: 비회원 -->
                                    <div v-if="!accessToken" class="sample-nonmember">
                                        <div>
                                            <p class="page-text18 color-grey5 align-center">로그인 후 <br class="pc" />샘플신청하기가 가능합니다.</p>
                                            <div class="btn-wrap align-center d-none d-md-block">
                                                <button class="button button--round button--point button--lg" @click="$router.push('/login?code=sample')">로그인하기</button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- E: 비회원 -->
                                </div>
                                <div class="sample-toast-btn-wrap">
                                    <button class="button button--square button--point" @click="order">샘플신청하기</button>
                                    <!-- <button class="button button--square button--point">로그인하기</button> -->
                                </div>
                            </div>
                            <!-- E: 샘플신청 -->
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <!-- 푸터 -->
        <main-footer></main-footer>
    </div>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

export default {
    components: {
        MainHeader,
        MainFooter,
    },
    data: () => {
        return {
            collections: [],
            collection: null,
            papers: [],
            paper: null,
            selected: [],
            carts: [],
            cart: null,

            showCollections: true,
            showPatterns: false,
            showProducts: false,

            basket: [],

            step1: 'current on',
            step2: '',
            btnStep2: 'line-height-0',
            step3: '',
            btnStep3: 'line-height-0',
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/v1/shop/categories", {
                        params: {
                            code: "collection",
                            isSample: true
                        }
                    });
                    this.collections = res.data?.categories[0]?.children.filter((collection) => collection.type == "내수용" && collection.isHidden !== true &&  collection.isSample === true );
                    this.basket = await this.getCarts();

                    this.showCollections = true;
                    this.step1 ='current on';
                    this.step2 ='';
                    this.btnStep2 ='line-height-0';
                    this.step3 ='';
                    this.btnStep3 ='line-height-0';
                    console.log(this.collections)
                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },
        getCollection(collection){
            if(!this.accessToken){
                this.$router.push("/login?code=sample");
                return;
            }
            if(collection === 'step'){
                this.showCollections = false;
                this.showPatterns = true;
                this.showProducts = false;
                console.log("참")
            } else {
                this.showCollections = false;
                this.showPatterns = true;
                console.log({collection})
                collection.children.filter((el) => { return el.isHidden === false && el.isSample === true })
                this.collection = collection
                this.showProducts = false;
                console.log("거짓")
            }
            this.step1 = 'on';
            this.step2 = 'current on';
            this.btnStep2 = 'line-height-0 current on';
            this.step3 = '';
            this.btnStep3 = 'line-height-0';
        },
        async getPatterns(texture){
            let { products } = await this.$http.get("/api/v1/shop/products", { params: { _category: texture._id } }).then(res => res.data);
            this.papers = products;
            this.showPatterns = false;
            this.showProducts = true;

            this.step1 = 'on';
            this.step2 = 'on';
            this.btnStep2 = 'line-height-0 on';
            this.step3 = 'current on';
            this.btnStep3 = 'line-height-0 current on';
        },
        async addToCart(paper){
            try{
                this.basket.push({
                    _product: paper._id,
                    product: paper,
                    name: "본품",
                    price: paper.price,
                    salePrice: paper.salePrice,
                    discountPrice: paper.discountPrice,
                    stock: paper.stock,
                    amount: 1,
                })
                this.$store.dispatch("addToBasket", this.basket);
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        async order(){
			try{
                let paperList = []
                for (let el of this.selected) {
                var { carts } = await this.postCart({
                    carts: [{
                    _product: el.product._id,
                    product: el,
                    name: "본품",
                    price: el.product.price,
                    salePrice: el.product.salePrice,
                    discountPrice: el.product.discountPrice,
                    stock: el.product.stock,
                    amount: 1,
                    }]
                });
                this.$store.dispatch("addToCart", carts);
                    paperList.push({ carts: carts, product: el.product });
                }
                if (paperList.length === 0) throw new Error("샘플신청하실 상품을 선택해주세요");
                this.$store.dispatch("pickup", paperList.map((cart) => { return cart.carts[0]._id; }));
                this.$router.push("/shop/order");
                this.$store.dispatch("setPickSample", this.selected);
			}
			catch(error){
				alert(error.message);
			}
		},
        getCarts(){
            return this.$store.state.baskets
		},
        postCart(data){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post("/api/v1/me/carts", data);

                    resolve(res.data);
                }
                catch(error){
                    reject(error);
                }
            });
        },
        sampleToggle() {
            var sampleToast = document.querySelector('.sample-col--toast');
            sampleToast.classList.toggle('active');
        },
        async remove(index){
            this.basket.splice(index, 1)
            this.$store.dispatch("addToBasket", this.basket);
		},
    },
    computed: {
		accessToken(){
			return this.$store.state.accessToken;
		},
		payload(){
			return this.$store.state.payload;
		},
        cartsMap(){
			var map = new Map();
			for(var i in this.basket){
				var cart = this.basket[i];
				var value = map.get(cart._product);
				if(!cart.product) continue;
				if(value) value.carts.push(cart);
				else value = { product: cart.product, carts: [cart] };
				map.set(cart._product, value);
			}
			return Array.from(map.values());
		},
	},
};
</script>
