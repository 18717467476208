var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('ul', {
    staticClass: "tab-list tab-list--tooth"
  }, [_c('li', {
    class: {
      'on': _vm.type == '회원서비스'
    }
  }, [_c('a', {
    staticClass: "button",
    on: {
      "click": function ($event) {
        _vm.type = '회원서비스';
      }
    }
  }, [_vm._v("회원서비스")])]), _c('li', {
    class: {
      'on': _vm.type == '상품'
    }
  }, [_c('a', {
    staticClass: "button",
    on: {
      "click": function ($event) {
        _vm.type = '상품';
      }
    }
  }, [_vm._v("상품")])]), _c('li', {
    class: {
      'on': _vm.type == '배송'
    }
  }, [_c('a', {
    staticClass: "button",
    on: {
      "click": function ($event) {
        _vm.type = '배송';
      }
    }
  }, [_vm._v("배송")])]), _c('li', {
    class: {
      'on': _vm.type == '교환/반품'
    }
  }, [_c('a', {
    staticClass: "button",
    on: {
      "click": function ($event) {
        _vm.type = '교환/반품';
      }
    }
  }, [_vm._v("교환/반품")])]), _c('li', {
    class: {
      'on': _vm.type == '입금/결제'
    }
  }, [_c('a', {
    staticClass: "button",
    on: {
      "click": function ($event) {
        _vm.type = '입금/결제';
      }
    }
  }, [_vm._v("입금/결제")])]), _c('li', {
    class: {
      'on': _vm.type == '기타'
    }
  }, [_c('a', {
    staticClass: "button",
    on: {
      "click": function ($event) {
        _vm.type = '기타';
      }
    }
  }, [_vm._v("기타")])])]), _c('div', {
    staticClass: "board-table board-table--faq",
    attrs: {
      "role": "table"
    }
  }, _vm._l(_vm.faqs.filter(function (faq) {
    return faq.type == _vm.type;
  }), function (faq) {
    return _c('div', {
      attrs: {
        "role": "rowgroup"
      }
    }, [_c('div', {
      staticClass: "question",
      attrs: {
        "role": "row",
        "data-idx": "1",
        "data-answer": faq.opened ? 'opened' : 'closed'
      },
      on: {
        "click": function ($event) {
          _vm.faqs.forEach(function (item) {
            return _vm.$set(item, 'opened', item == faq ? !faq.opened : false);
          });
        }
      }
    }, [_c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "title"
    }, [_c('a', {
      attrs: {
        "role": "button"
      }
    }, [_c('i', {
      staticClass: "icon"
    }, [_vm._v("Q")]), _vm._v(_vm._s(faq.subject))])])])]), _c('div', {
      staticClass: "answer",
      attrs: {
        "role": "row",
        "data-idx-answer": "1"
      }
    }, [_c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "answer"
    }, [_vm._m(0, true), _c('p', {
      staticClass: "pr",
      domProps: {
        "innerHTML": _vm._s(faq.content.replace(/\n/g, '<br>'))
      }
    })])])])]);
  }), 0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_c('i', {
    staticClass: "icon"
  }, [_vm._v("A")]), _vm._v("자주 묻는 질문에 대한 답입니다.")]);

}]

export { render, staticRenderFns }