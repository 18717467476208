var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-photo"
  }, [_c('ul', _vm._l(_vm.boards, function (board) {
    return _c('li', [_c('div', {
      staticClass: "photo-wrap",
      on: {
        "click": function ($event) {
          return _vm.navigate(board);
        }
      }
    }, [_c('div', {
      staticClass: "photo-header"
    }, [_c('div', {
      staticClass: "photo-img"
    }, [_c('a', {
      style: {
        backgroundImage: `url('${board.thumb}')`
      },
      attrs: {
        "role": "link"
      }
    })])]), _c('div', {
      staticClass: "photo-body"
    }, [_c('a', {
      attrs: {
        "role": "link"
      }
    }, [_c('h2', {
      staticClass: "photo-title"
    }, [_vm._v(_vm._s(board.subject))]), _c('p', {
      staticClass: "photo-text"
    }, [_vm._v(_vm._s(board.summary))])])])])]);
  }), 0)]), _c('pagination-component', {
    attrs: {
      "count": _vm.count
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', _vm.page);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }