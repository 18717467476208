var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$route.query._notification === undefined ? _c('notification-list') : _c('notification-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }