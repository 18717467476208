<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
            <div class="container">
                <div class="collection-index-container">
                    <div class="page-head">
                        <div class="page-head__inner">
                            <h1 class="page-head__title">COMMUNITY</h1>
                            <ul class="breadcrumb">
                                <li class="breadcrumb__list">항상 고객을 생각하는 마음으로 최선을 다하는 디아이디</li>
                                <!-- <li class="breadcrumb__list">컬렉션</li>
                                <li class="breadcrumb__list">국내</li>
                                <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li> -->
                            </ul>
                        </div>
                    </div>
					<div class="center-ban">
						<h1 class="center-ban__title">디아이디 커뮤니티</h1>
						<p class="center-ban__pr">고객님과 함께하는 디아이디 커뮤니티 입니다.</p>
					</div>
					<div class="center-tab">
						<ul class="tab-list tab-list--block">
							<!-- <li><a class="button" href="/center/faq">FaQ</a></li> -->
							<li><a class="button" href="/center/notice" name="button">공지사항</a></li>
							<li><a class="button" href="/community/sponsor" name="button">협찬정보</a></li>
							<li class="on"><a class="button" href="/community/event" name="button">이벤트</a></li>
							<li><a class="button" href="/community/interior" name="button">인테리어 상담</a></li>
							<li><a class="button" href="/community/sample" name="button">샘플신청하기</a></li>
						</ul>
					</div>
                    <div class="center-board">
                        <!-- 1. 게시판 리스트: 포토 베이직2 스킨 -->
                        <board-photo-basic2-list v-model="page" :category="category" :boards="boards" :count="pageCount" @changeCategory="changeCategory"></board-photo-basic2-list>
                    </div>
                </div>
            </div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from '@/components/client/inc/main-header.vue';
import MainFooter from '@/components/client/inc/main-footer.vue';
import BoardPhotoBasic2List from "@/components/client/board/skin/board-photo-basic2/list.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
        BoardPhotoBasic2List
	},
	data: () => {
		return {
			category: "진행중 이벤트",
			boards: [],

			page: 1,
			pageCount: 0,
			limit: 15,
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		async init(){
			try{
				await this.getBoards();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},
		async search(){
			try{
				await this.getBoards();
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},
		changeCategory(category){
			this.page = 1;
			this.category = category;
			this.getBoards();
		},
		getBoards(){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.get('/api/v1/boards', {
						headers: {
							"skip": (this.page - 1) * this.limit,
							"limit": this.limit
						},
						params: {
							code: "event",
							category: this.category
						}
					});

					this.boards = res.data.boards;
					this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);

					resolve();
				}
				catch(error){
					reject(error);
				}
			});
		},
	},
	watch :{
		page(){
			this.search();
		},
		category(){
			this.search();
		}
	}
}
</script>
